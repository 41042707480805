import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { OptionType } from '../BrandProfilePage/type'
import { customStylesDropDownAssets } from '../IdeasDetailPage/constants'
import { CustomOptionBig } from '../../components/DetailCardView/component'
import productsStore, { getProductData } from '../../storeZustand/productsStore'
import { formatToOptionTypeProduct } from '../../storeZustand/brandCustomizationStore'
import { NeedleImage } from '../../components/NeedleImage'
interface MultipleProductSelectProps {
    setSelectedProduct: React.Dispatch<React.SetStateAction<OptionType[]>>
    selectedProduct: OptionType[]
}
export const MultipleProductSelect = ({
    selectedProduct,
    setSelectedProduct,
}: MultipleProductSelectProps) => {
    const MultiValue = () => null
    const [displayMarket, setDisplayMarket] = useState<boolean>(true)
    const [listProductOptions, setListProductOptions] = useState<OptionType[]>(
        []
    )

    const handleChange = (selected: any) => {
        setSelectedProduct(selected || [])
    }
    const handleRemove = (selected: OptionType) => {
        const newSelectOptions = selectedProduct.filter(
            (option: OptionType) => option.value !== selected.value
        )
        setSelectedProduct(newSelectOptions)
    }
    const { products, isLoadedProducts } = productsStore((state) => ({
        products: state.products,
        isLoadedProducts: state.isLoadedProducts,
    }))

    useEffect(() => {
        getProductData()
    }, [])

    useEffect(() => {
        setListProductOptions(formatToOptionTypeProduct(products))
    }, [products])

    return (
        <div className="pt-6">
            <div className="font-subtitle pb-2">Products</div>
            <div className="relative">
                <Select
                    isMulti
                    isClearable={false}
                    options={listProductOptions}
                    value={selectedProduct}
                    menuPortalTarget={document.body}
                    onChange={handleChange}
                    placeholder=""
                    isLoading={!isLoadedProducts}
                    components={{
                        MultiValue,
                        IndicatorSeparator: () => null,
                        Option: CustomOptionBig,
                    }}
                    loadingMessage={() => 'Fetching options...'}
                    styles={customStylesDropDownAssets}
                    backspaceRemovesValue={false}
                    closeMenuOnSelect={false}
                    onFocus={() => setDisplayMarket(false)}
                    onBlur={() => setDisplayMarket(true)}
                    menuPlacement="top"
                />
                <label
                    htmlFor="market-select"
                    className="text-black absolute bottom-3 left-4 font-dm"
                >
                    {displayMarket && 'Select product'}
                </label>
            </div>
            <div className="flex flex-wrap flex-row">
                {selectedProduct.map((product: OptionType, index) => (
                    <div key={product.value + index} className="pr-2 pt-2">
                        <div
                            className="border border-beige-outline rounded-xl py-2 pl-2 pr-6 flex flex-row 
                            justify-between items-center font-body-text w-228px"
                        >
                            <NeedleImage
                                src={product.image}
                                alt="checked-icon"
                                className={`h-12 w-12 mr-10px rounded-md`}
                            />
                            <div className="line-clamp-2">{product.label}</div>
                            <div
                                onClick={() => handleRemove(product)}
                                className="h-4 w-4 flex-shrink-0"
                            >
                                <img
                                    src="/assets/icons/close-icon.svg"
                                    alt="close-icon"
                                    className="h-4 w-4 ml-10px cursor-pointer"
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default MultipleProductSelect

import React, { useEffect, useState } from 'react'
import './GettingResultLayout.scss'
import { Navigate, useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import LoadingScreen from '../../views/LoadingScreen/LoadingScreen'
import { PATH } from '../../constants/path'
import useMediaQueryHook from '../../hooks/useMediaQueryHook'
import useUserProfile from '../../hooks/useUserProfile'
import useWorkspace from '../../hooks/useWorkspace'
import NavigationList from './NavigationList'
import BottomPartNavigation from './BottomPartNavigation'
import HeaderPartNavigation from './HeaderPartNavigation'
import DetailScreen from './DetailScreen'
import ideasStore from '../../storeZustand/ideasStore'
import useGrowthPlan from '../../hooks/useGrowthPlan'
import useEnhanceYourAI from '../../hooks/useEnhanceYourAI'
import brandStore from '../../storeZustand/brandCustomizationStore'
interface Props {
    children: React.ReactNode
}

export default function GettingResultLayout(props: Props) {
    const { userProfile: currentUser, isLoading: isLoadingCurrentUser } =
        useUserProfile()

    const { data: workspace, isLoading: isLoadingWorkspace } = useWorkspace(
        currentUser?.default_workspace
    )
    const { dataBrandProfile, dataAssetsTemplate } = useEnhanceYourAI()

    const {
        data: growthPlan,
        isLoading: isGrowthPlan,
        reloadGrowthPlan,
    } = useGrowthPlan()

    const { id } = useParams()

    const { setupDataIdeas, isShouldReload, setShouldReload } = ideasStore(
        (state) => ({
            setupDataIdeas: state.setupDataIdeas,
            isShouldReload: state.isShouldReload,
            setShouldReload: state.setShouldReload,
        })
    )
    const { setupBrandProfile, setupAssetsTemplates } = brandStore((state) => ({
        setupBrandProfile: state.setupBrandProfile,
        setupAssetsTemplates: state.setupAssetsTemplates,
    }))
    useEffect(() => {
        if (dataBrandProfile) {
            setupBrandProfile(dataBrandProfile)
        }
    }, [dataBrandProfile])

    useEffect(() => {
        if (dataAssetsTemplate) {
            setupAssetsTemplates(dataAssetsTemplate)
        }
    }, [dataAssetsTemplate])

    useEffect(() => {
        if (growthPlan?.ideas) {
            setupDataIdeas(growthPlan.ideas, growthPlan.currency)
        }
    }, [growthPlan])

    useEffect(() => {
        if (isShouldReload && id === undefined) {
            setShouldReload(false)
            reloadGrowthPlan()
        }
    }, [isShouldReload, id])

    const [isOpen, setIsOpen] = useState(false)
    const currentPath = window.location.pathname

    useEffect(() => {
        setIsOpen(false)
    }, [currentPath])

    const currentState = useMediaQueryHook()
    const creditBalance =
        (workspace?.paid_credit ?? 0) +
        (workspace?.free_credit ?? 0) -
        (workspace?.used_credit ?? 0)

    if (
        isLoadingWorkspace ||
        isLoadingCurrentUser ||
        !currentUser ||
        !dataBrandProfile
    )
        return (
            <div className="h-screen">
                <LoadingScreen />
            </div>
        )

    if (!dataBrandProfile?.brand_metrics?.marketing_spend) {
        return <Navigate to={PATH.onBoarding} />
    }

    const isMobile = currentState !== 'laptop'

    const toggleMenu = () => {
        setIsOpen(!isOpen)
    }

    return (
        <Box
            className={
                'result-layout-wrapper bg-beige flex w-full min-h-lvh relative'
            }
        >
            <div
                className={`flex flex-col justify-between bg-white fixed z-50 h-full w-280px ${isOpen || !isMobile ? 'translate-x-0' : '-translate-x-72'} transition-all duration-300 overflow-hidden`}
            >
                <div
                    className={`${isOpen || !isMobile ? 'block' : 'hidden'} flex flex-col justify-between h-full`}
                >
                    <div
                        className={`${isMobile && 'pt-14'} overflow-y-auto flex-grow`}
                    >
                        <div
                            className={`${isMobile ? 'py-6 border-t border-beige-outline-2 ' : 'pb-14'}`}
                        >
                            <HeaderPartNavigation
                                credit_balance={creditBalance}
                                isMobile={isMobile}
                                workspaceId={
                                    currentUser.default_workspace ?? ''
                                }
                            />
                        </div>
                        <div className={`${isMobile ? 'pl-4' : 'pl-8'}`}>
                            <NavigationList currentUser={currentUser} />
                        </div>
                    </div>
                    <div className="flex-shrink-0">
                        <BottomPartNavigation currentUser={currentUser} />
                    </div>
                </div>
            </div>
            <div className={`${isMobile ? 'w-full pt-14' : 'w-full ml-280px'}`}>
                <DetailScreen children={props.children} />
            </div>
            {isMobile && (
                <div className="fixed top-0 bg-white w-screen z-50 h-56px">
                    <div className="flex flex-row h-full justify-center relative items-center">
                        <div
                            className="absolute left-0 cursor-pointer p-4"
                            onClick={toggleMenu}
                        >
                            <img
                                src="/assets/svgs/menu-icon.svg"
                                alt="Needle logo"
                            />
                        </div>
                        <Box className="flex justify-center">
                            <img
                                src="/assets/svgs/needle-logo-2.svg"
                                alt="Needle logo"
                            />
                        </Box>
                    </div>
                </div>
            )}
            {isOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-40"
                    onClick={toggleMenu}
                />
            )}
        </Box>
    )
}

import { ReactNode, useEffect, useState } from 'react'
import { Flag, FlagProps } from '../Flag'
import { CoverBoxSize } from './CoverBoxType'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import Lottie from 'react-lottie'
import { doneIconAnimation } from '../LottieAnimation'
import { ConnectState } from '../../constants/type'
import { ButtonType, NeedleButton } from '../NeedleButton'
interface CoverBoxProps {
    name: string
    description?: string
    index?: number
    flag?: FlagProps
    children: ReactNode
    showClick?: (isShow: boolean, index: number) => void
    size?: CoverBoxSize
    isDropDown?: boolean
    connectStatus?: ConnectState
    keyFinish?: string
    isShowView?: boolean
}

export const CoverBox = ({
    name,
    description,
    index,
    flag,
    children,
    showClick,
    isDropDown = false,
    size = CoverBoxSize.normal,
    connectStatus,
    keyFinish = 'Connected',
    isShowView,
}: CoverBoxProps) => {
    const [isShow, setIsShow] = useState(false)
    const [isHovered, setIsHovered] = useState(false)
    const isDone =
        connectStatus &&
        connectStatus.currentConnect === connectStatus.maxConnect
    let width = 'w-full'
    let headerSize = 'font-header-2'
    let paddingWidth = 'px-6'

    useEffect(() => {
        if (isShowView && !isShow) {
            setIsShow(true)
        }
    }, [isShowView])

    if (size === CoverBoxSize.small) {
        width = 'w-332px'
        headerSize = 'font-header-3'
        paddingWidth = 'px-4'
    }

    const displaySideShowView =
        size === CoverBoxSize.normal &&
        ((flag && flag.flagNumber > 1) || isDropDown)

    const displayDownShowView =
        size === CoverBoxSize.small && isDropDown && flag && flag.flagNumber > 1

    const handleClickShow = (): void => {
        showClick && index !== undefined && showClick(!isShow, index)
        setIsShow(!isShow)
    }
    const handleMouseEnter = (): void => {
        setIsHovered(true)
    }

    const handleMouseLeave = (): void => {
        setIsHovered(false)
    }

    if (
        flag !== undefined &&
        flag.flagNumber === 0 &&
        size === CoverBoxSize.normal
    ) {
        return <div></div>
    }

    const renderShowAllButton = (): JSX.Element => {
        return (
            <div
                className="text-base flex flex-row items-center cursor-pointer justify-center"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <NeedleButton
                    border="py-2.5 px-3"
                    onClickFunction={() => {
                        handleClickShow()
                    }}
                    buttonType={ButtonType.White}
                    buttonCharacter={isShow ? 'Hide' : 'Show all'}
                />
            </div>
        )
    }

    return (
        <div
            className={`${
                !isShow || isHovered ? 'bg-beige-2' : 'bg-beige'
            } transition-colors duration-300 rounded-2xl flex flex-1 mt-6 h-full ${width}`}
        >
            <div className={`py-4 w-full ${paddingWidth}`}>
                <div className="flex flex-row font-sans items-center justify-between text-dark-blue">
                    <div className="flex flex-row items-center relative">
                        <div className={`${headerSize} relative`}>{name}</div>
                        {connectStatus &&
                            (isDone ? (
                                <div className="w-12 h-12 absolute -right-12">
                                    <Lottie
                                        options={doneIconAnimation}
                                        isClickToPauseDisabled={true}
                                    />
                                </div>
                            ) : (
                                <div className="flex flex-row item-center items-center pl-3">
                                    <div className="h-5 w-5">
                                        <CircularProgressbar
                                            value={connectStatus.currentConnect}
                                            maxValue={connectStatus.maxConnect}
                                            strokeWidth={16}
                                            styles={buildStyles({
                                                pathColor: '#FF9D3B',
                                                trailColor: 'white',
                                                strokeLinecap: 'round',
                                                rotation: 0.03,
                                            })}
                                        />
                                    </div>
                                    <div className="font-subtitle pt-1 pl-2">
                                        {`${connectStatus.currentConnect}/${connectStatus.maxConnect} ${keyFinish}`}
                                    </div>
                                </div>
                            ))}
                        {flag && (
                            <Flag
                                flagNumber={flag.flagNumber}
                                color={flag.color}
                            />
                        )}
                    </div>
                    {displaySideShowView && renderShowAllButton()}
                </div>
                {description && (
                    <div className="font-body-text pt-4">{description}</div>
                )}
                {children}
                {displayDownShowView && (
                    <div className="pt-5">{renderShowAllButton()}</div>
                )}
            </div>
        </div>
    )
}

import React, { useEffect, useState } from 'react'
import { ButtonType, NeedleButton } from '../../../components/NeedleButton'
import { Dialog } from '@mui/material'
import { Concept, Product } from '../../../utils/needleEnum'
import { ConceptType } from '../constants'
import { OptionType } from '../../BrandProfilePage/type'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import MultipleProductSelect from '../../AddAnIdeaPage/MultipleProductSelect'
import { TextareaAutosize } from '@mui/base/TextareaAutosize/TextareaAutosize'
import { useParams } from 'react-router-dom'
import useIdeaDetail, { changeConceptsData } from '../../../hooks/useIdeaDetail'
import {
    formatToOptionTypeProduct,
    formatToProductFromOption,
} from '../../../storeZustand/brandCustomizationStore'
import { HtmlToReact } from '../../../utils/utils'
interface EditConceptProps {
    currentConcept: Concept
    isPreview: boolean
}
export const EditConcept = ({
    currentConcept,
    isPreview = false,
}: EditConceptProps) => {
    const { id } = useParams()
    const { reload } = useIdeaDetail(id)
    const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false)
    const [title, setTitle] = useState<string>(currentConcept.title)
    const [description, setDescription] = useState<string>(
        HtmlToReact(currentConcept.description)
    )

    const [productOptions, setProductsOption] = useState<OptionType[]>(
        formatToOptionTypeProduct(currentConcept.products)
    )
    const [shouldSendDate, setShouldSendDate] = useState<Date | undefined>(
        currentConcept.suggested_send_date
            ? new Date(currentConcept.suggested_send_date._seconds * 1000)
            : undefined
    )
    const [isDisable, setIsDisable] = useState<boolean>(true)
    const isHaveDate = currentConcept.suggested_send_date

    const handleChange = (selected: any): void => {
        setProductsOption(selected || [])
    }

    const saveUpdateConcepts = (): void => {
        if (id) {
            const utcShouldSendDate = shouldSendDate
                ? shouldSendDate.getTime() / 1000
                : undefined
            const timezone = shouldSendDate ? Intl.DateTimeFormat().resolvedOptions().timeZone : currentConcept.timezone
            changeConceptsData(
                id,
                currentConcept.id,
                {
                    title: title,
                    description: description,
                    suggested_send_date: utcShouldSendDate
                        ? new Date(utcShouldSendDate * 1000)
                        : undefined,
                    products: formatToProductFromOption(productOptions),
                    timezone,
                },
                reload
            )
        }
        setIsOpenDialog(false)
    }

    useEffect(() => {
        if (
            description.trim() !== '' &&
            title.trim() !== '' &&
            (!isHaveDate || shouldSendDate !== undefined)
        ) {
            setIsDisable(false)
        } else {
            setIsDisable(true)
        }
    }, [description, title, shouldSendDate])

    const setupOpenDialog = (): void => {
        setIsOpenDialog(true)
        setShouldSendDate(
            currentConcept.suggested_send_date
                ? new Date(currentConcept.suggested_send_date._seconds * 1000)
                : undefined
        )
        setTitle(currentConcept.title)
        setDescription(HtmlToReact(currentConcept.description))
        setProductsOption(formatToOptionTypeProduct(currentConcept.products))
    }

    return (
        <div>
            <div>
                <NeedleButton
                    buttonCharacter="Edit concept"
                    buttonType={ButtonType.White}
                    onClickFunction={isPreview ? () => null : setupOpenDialog}
                />
            </div>
            <Dialog
                open={isOpenDialog}
                sx={{
                    '& .MuiDialog-paper': {
                        maxWidth: 'none',
                    },
                }}
            >
                <div className="w-680px font-body-text">
                    <div className="text-dark-blue pt-6 px-6 pb-4">
                        <div className="font-header-2">Edit concept</div>
                        <div className="font-body-text pt-4">
                            When editing, remember that more details = magic!
                            Share context, ideas, links — anything to help me
                            bring your concept to life.
                        </div>
                        <div className="font-subtitle pt-6">
                            Title<span className="text-red-theme">*</span>
                        </div>
                        <input
                            type="text"
                            value={title}
                            placeholder="Concept title"
                            onChange={(e) => setTitle(e.target.value)}
                            className="mt-2 py-3 px-4 border rounded-lg focus:ring-1 focus:ring-inset focus:ring-dark-blue w-full"
                        />
                        <div className="pt-6 font-subtitle">
                            Description<span className="text-red-theme">*</span>
                        </div>
                        <TextareaAutosize
                            id="custom-review"
                            className="border border-beige-outline font-body-text overflow-auto flex
                            py-3 px-4 w-full rounded-lg focus:outline-dark-blue mt-2"
                            placeholder={'Concept description'}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <MultipleProductSelect
                            setSelectedProduct={handleChange}
                            selectedProduct={productOptions}
                        />
                        {isHaveDate && (
                            <div>
                                <div className="font-subtitle pt-6">
                                    Suggested send date
                                    <span className="text-red-theme">*</span>
                                </div>
                                <DatePicker
                                    selected={shouldSendDate}
                                    onChange={(date) => {
                                        if (date) {
                                            setShouldSendDate(date)
                                        }
                                    }}
                                    placeholderText="Select date"
                                    dateFormat="MMMM dd, yyyy (EEEE)"
                                    className="border border-beige-outline rounded-lg px-4 mt-2 flex items-center focus:outline-none py-3 w-632px"
                                />
                            </div>
                        )}
                    </div>
                    <div className="bg-beige-outline h-1px w-full absolute left-0" />
                    <div className="flex flex-row items-end py-4 pr-6 justify-end">
                        <div className="flex">
                            <NeedleButton
                                buttonCharacter="Cancel"
                                onClickFunction={() => setIsOpenDialog(false)}
                                buttonType={ButtonType.White}
                            />
                        </div>
                        <div className="pl-2 flex bg-red-50">
                            <NeedleButton
                                buttonCharacter={'Save changes'}
                                onClickFunction={saveUpdateConcepts}
                                isDisable={isDisable}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default EditConcept

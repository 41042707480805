import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PATH } from '../../constants/path'
import useUserData from '../../hooks/useUserData'
import { EventType } from '../HomeScreen/constants'
import IdeasDetailTopSummary from './IdeasDetailTopSummary'
import IdeasDetailBottomConcepts from './IdeasDetailBottomConcepts/IdeasDetailBottomConcepts'
import IdeasDetailDialog from './Dialog/IdeasDetailDialog'
import EmailConnectBlocked from './BlockedCase/EmailConnectBlocked'
import BrandProfileBlocked from './BlockedCase/BrandProfileBlocked'
import UploadAssetBlocked from './BlockedCase/UploadAssetBlocked'
import useIdeaDetail, { changeIdeaStatus } from '../../hooks/useIdeaDetail'
import { Idea, IdeaBlockerType, IdeaStatus } from '../../utils/needleEnum'
import { IdeaDetailServices } from '../../services/IdeaDetail/ideadetail.services'
import { usePageTitle } from '../HomeScreen/component'
import useGrowthPlan from '../../hooks/useGrowthPlan'
import useUserProfile from '../../hooks/useUserProfile'
import BasicAssetsBlocked from './BlockedCase/BasicAssetsBlocked'
import { AdminCustomBlocked } from './BlockedCase/AdminCustomBlocked'
import RequiredInformationForm from './BlockedCase/RequiredInformationForm'
enum BackButtonType {
    Ideas = 'Ideas',
    Actions = 'Actions',
}
export const IdeasDetailPage = () => {
    usePageTitle('Needle - Idea Detail')
    const navigate = useNavigate()
    const { userProfile } = useUserProfile()
    const { reloadGrowthPlan } = useGrowthPlan()
    const { id, userId: workspace_id } = useParams()
    const { data: useIdea, reload, isLoading } = useIdeaDetail(id)
    const { data: userData } = useUserData(workspace_id)
    const isFailLoading = !isLoading && !useIdea
    const [ideasStatus, setIdeasStatus] = useState<EventType>(EventType.Empty)
    const [blockedList, setBlockedList] = useState<IdeaBlockerType[]>([])
    const [backButtonType, setBackButtonType] = useState<
        BackButtonType | undefined
    >(isFailLoading ? BackButtonType.Ideas : undefined)

    const setupIdeasStatus = (currentIdeas: Idea): void => {
        switch (currentIdeas.status) {
            case IdeaStatus.done:
                setIdeasStatus(EventType.Done)
                setBackButtonType(BackButtonType.Actions)
                break
            case IdeaStatus.needle_is_working:
                setIdeasStatus(EventType.Doing)
                setBackButtonType(BackButtonType.Actions)
                break
            case IdeaStatus.archived:
                setIdeasStatus(EventType.Archived)
                setBackButtonType(BackButtonType.Ideas)
                break
            case IdeaStatus.blocked:
                setIdeasStatus(EventType.Blocked)
                setBackButtonType(BackButtonType.Actions)
                break
            case IdeaStatus.new_asset:
            case IdeaStatus.read_asset:
                setIdeasStatus(EventType.AssetReady)
                setBackButtonType(BackButtonType.Actions)
                break
            case IdeaStatus.new:
            case IdeaStatus.read:
                setIdeasStatus(EventType.Empty)
                setBackButtonType(BackButtonType.Ideas)
                break
            default:
                setIdeasStatus(EventType.Empty)
        }
    }

    useEffect(() => {
        if (useIdea && userProfile) {
            if (
                useIdea.status === IdeaStatus.new &&
                id &&
                userProfile.role !== 'admin'
            ) {
                changeIdeaStatus(id, IdeaStatus.read, reload)
            }
            if (useIdea.status === IdeaStatus.new_asset && id) {
                changeIdeaStatus(id, IdeaStatus.read_asset, reload)
            }
            setupIdeasStatus(useIdea)
            if (useIdea?.metadata?.blockers) {
                setBlockedList(useIdea.metadata.blockers)
            } else {
                setBlockedList([])
            }
        }
    }, [useIdea, userProfile])

    const handleBlockAssets = async (
        index: number,
        isSubmit: boolean,
        driveLink: string
    ) => {
        const newList = [...blockedList]
        newList.splice(index, 1)
        setBlockedList(newList)
        if (id) {
            if (isSubmit) {
                await IdeaDetailServices.updateAssets(id, {
                    assets_url: driveLink,
                })
            } else {
                await IdeaDetailServices.skipIdeaBlock(id)
            }
            reload()
            reloadGrowthPlan()
        }
    }

    const blockedCaseItem = (blockedType: IdeaBlockerType, index: number) => {
        switch (blockedType) {
            case IdeaBlockerType.profile_essentials:
                return <BrandProfileBlocked />
            case IdeaBlockerType.email_templates:
                return <EmailConnectBlocked />
            case IdeaBlockerType.basic_assets:
                return <BasicAssetsBlocked />
            case IdeaBlockerType.additional_assets:
                return (
                    <UploadAssetBlocked
                        skipBlockedList={(isSubmit, driveLink) =>
                            handleBlockAssets(index, isSubmit, driveLink)
                        }
                        isVideo={true}
                    />
                )
            case IdeaBlockerType.required_information:
                return useIdea?.required_information ? (
                    <RequiredInformationForm
                        ideaId={id ?? ''}
                        requiredInformation={useIdea?.required_information!!}
                        onSubmitSuccess={() => {
                            reload()
                            reloadGrowthPlan()
                        }}
                    />
                ) : null

            default:
                return <AdminCustomBlocked blockedInfo={blockedType} />
        }
    }
    const backButtonHandle = (): void => {
        navigate(
            backButtonType === BackButtonType.Actions
                ? PATH.actions
                : PATH.ideasPage
        )
    }
    return (
        <div className="bg-beige h-full w-full transition-colors text-dark-blue flex flex-col items-center">
            <IdeasDetailDialog userData={userData} id={id ? id : ''} />
            <div className="w-680px mb-15px">
                <div className="flex">
                    <div
                        className="flex cursor-pointer mt-8"
                        onClick={backButtonHandle}
                    >
                        <img
                            src="/assets/icons/back-icon.svg"
                            alt="back-icon"
                        />
                        <div className="pl-2 font-subtitle text-dark-blue">
                            {backButtonType && `Back to ${backButtonType}`}
                        </div>
                    </div>
                </div>
                <IdeasDetailTopSummary
                    currentIdeas={useIdea}
                    ideasStatus={ideasStatus}
                    isFailLoading={isFailLoading}
                />
                {ideasStatus === EventType.Blocked && (
                    <div>
                        <div className="font-header-3 pt-8 pb-4">
                            I'm blocked from working on this:
                        </div>
                        <div className="gap-4 flex flex-col">
                            {blockedList.map(
                                (
                                    blockedCase: IdeaBlockerType,
                                    index: number
                                ) =>
                                    blockedCaseItem(blockedCase, index) ? (
                                        <div
                                            className="bg-white rounded-lg border-red-theme border-2 p-6 shadow-un-click-card"
                                            key={blockedCase}
                                        >
                                            {blockedCaseItem(
                                                blockedCase,
                                                index
                                            )}
                                        </div>
                                    ) : (
                                        <></>
                                    )
                            )}
                        </div>
                    </div>
                )}
                {useIdea?.concepts && useIdea?.concepts.length > 0 && (
                    <div>
                        <div className="font-header-3 pt-8">Concepts</div>
                        {useIdea.concepts.map((concept, index) => (
                            <IdeasDetailBottomConcepts
                                concept={concept}
                                ideasStatus={ideasStatus}
                                key={concept.title + index}
                                idea={useIdea}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default IdeasDetailPage

import {
    Box,
    Container,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import React, { useState } from 'react'
import Typed from 'react-typed'
import { RouteType, resultRoutes } from '../../routes'
import { useNavigate } from 'react-router-dom'
import useSWR from 'swr'
import { Services } from '../../services'
import { useAppDispatch } from '../../redux/store'
import { logOut } from '../../redux/authReducer'
import axios from 'axios'
import { ASK_NEEDLE_API } from '../../services/api/config'
import LoadingScreen from '../LoadingScreen/LoadingScreen'
import { Result } from '../../utils/enum'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase/client'

export const AskNeedleChatBoxComponent = (props: { userId?: string }) => {
    const [status, setStatus] = useState<
        'idle' | 'loading' | 'success' | 'error'
    >('idle')
    const [messages, setMessages] = useState<
        {
            sender: 'ai' | 'user' | 'error'
            content: string
        }[]
    >([
        {
            sender: 'ai',
            content:
                "Hey there, nice to meet you. I'm Needle, your AI assistant. \n\nI'm here to make your life easier in any way I can. You can ask anything about your data and use me as your personal data analyst.\n\n Ask me anything?",
        },
    ])

    const getAnswerFromAPI = async (arg: { question: string }) => {
        const res = await axios.post(`${ASK_NEEDLE_API}/ask`, {
            question: arg.question,
            db_name: `${
                process.env.REACT_APP_FIREBASE_PROJECT_ID?.includes('prod')
                    ? 'prod-needle'
                    : 'dev-needle'
            }.dbt_${
                process.env.REACT_APP_ENVIRONMENT ?? 'development'
            }.${props.userId?.toLowerCase()}_business_overview`,
        })
        return res?.data?.response?.AIresponse?.text ?? 'error'
    }

    const [userInput, setUserInput] = useState('')
    const handleKeyPress = async (event: any) => {
        try {
            if (event.key === 'Enter' && !event.shiftKey) {
                if (userInput === '') return
                setMessages((val) => {
                    return [
                        ...val,
                        {
                            sender: 'user',
                            content: userInput,
                        },
                    ]
                })
                const question = userInput
                setUserInput('')
                setStatus('loading')
                const answer = await getAnswerFromAPI({ question: question })
                setMessages((val) => {
                    return [
                        ...val,
                        {
                            sender: 'ai',
                            content: answer,
                        },
                    ]
                })
                setStatus('success')
            }
        } catch (e) {
            setStatus('error')
            setMessages((val) => {
                return [
                    ...val,
                    {
                        sender: 'error',
                        content: 'An error occurs, please try again',
                    },
                ]
            })
        }
    }
    return (
        <Container
            sx={{ marginTop: '87px', padding: '24px 32px 100px' }}
            maxWidth={'md'}
        >
            <Stack spacing={5}>
                {messages.map((item, index) => {
                    return (
                        <Box
                            key={index}
                            sx={{
                                paddingLeft: item.sender === 'ai' ? 0 : '14px',
                                maxWidth: '100%',
                                overflow: 'hidden',
                            }}
                        >
                            {item.sender === 'error' && (
                                <Typography
                                    key={index}
                                    fontSize={20}
                                    fontWeight={600}
                                    lineHeight={'32px'}
                                    color={'red'}
                                >
                                    {item.content}
                                </Typography>
                            )}
                            {item.sender === 'error' ||
                                item.content.split('\n').map((text, index) => {
                                    if (text === '') return <br key={index} />
                                    return (
                                        <Typography
                                            key={index}
                                            fontSize={20}
                                            fontWeight={
                                                item.sender === 'ai' ? 500 : 400
                                            }
                                            lineHeight={
                                                item.sender === 'ai'
                                                    ? '32px'
                                                    : '24px'
                                            }
                                            color={
                                                item.sender === 'ai'
                                                    ? 'rgba(0, 0, 0, 0.8)'
                                                    : '#737373'
                                            }
                                        >
                                            {text}
                                        </Typography>
                                    )
                                })}
                        </Box>
                    )
                })}
                {status === 'loading' && (
                    <Typed strings={['...']} typeSpeed={30} loop={true} />
                )}
                {status === 'loading' || (
                    <TextField
                        multiline
                        sx={{
                            '& fieldset': {
                                border: 'none !important',
                                boxShadow: 'none',
                                outlined: 'none !important',
                            },
                        }}
                        onBlur={({ target }) => target.focus()}
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                        autoFocus
                        onKeyDown={handleKeyPress}
                        variant="outlined"
                    />
                )}
            </Stack>
        </Container>
    )
}

const AskNeedleAI = () => {
    const [user] = useAuthState(auth)
    const drawerWidth = 92
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { isLoading } = useSWR(`/user/currentUserResult`, async (id) => {
        const [userResultResponse, userProfileResponse] = await Promise.all([
            Services.getUserResult(),
            Services.getUserProfile(),
        ])
        const userResult = userResultResponse as Result
        const userProfile = userProfileResponse.data

        return {
            userResult,
            userProfile,
        }
    })
    if (isLoading) return <LoadingScreen />

    return (
        <Box
            className={'result-layout-wrapper bg-beige'}
            sx={{
                display: 'flex',
                width: '100%',
                minHeight: '100vh',
                position: 'relative',
            }}
        >
            {/* <SpeakToAdviserChatBox /> */}
            <Box
                className="nav-drawer"
                sx={{
                    overflow: 'hidden !important',
                    display: 'flex',
                    width: drawerWidth,
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <Box>
                    <Box
                        sx={{
                            marginTop: '40px',
                            marginBottom: '36px',
                            marginLeft: '24px',
                        }}
                    >
                        <img
                            src="/assets/svgs/needle-icon.svg"
                            alt="Needle logo"
                        />
                    </Box>
                    <List>
                        {resultRoutes
                            .filter(
                                (item) =>
                                    item.routeType === RouteType.MainFunction
                            )
                            .map((item) => (
                                <ListItem
                                    onClick={() => navigate(item.path)}
                                    sx={{ mb: 0.5 }}
                                    key={item.path}
                                    disablePadding
                                >
                                    <ListItemButton>
                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                    </List>
                </Box>
                <Box className="bottom-action">
                    <Stack
                        onClick={() => {
                            dispatch(logOut())
                        }}
                        spacing={1.5}
                        className="text-btn"
                        direction={'row'}
                    >
                        <img src="/assets/svgs/logout.svg" alt="logout" />
                    </Stack>
                </Box>
            </Box>
            <Container sx={{ padding: '0 !important' }} maxWidth={'xl'}>
                <Box
                    sx={{
                        marginLeft: `${drawerWidth}px`,
                    }}
                >
                    <AskNeedleChatBoxComponent userId={user?.uid} />
                </Box>
            </Container>
        </Box>
    )
}

export default AskNeedleAI

import React from 'react'
import { ReactComponent as NeedleLogoWithText } from '../../assets/svgs/needle-logo-yellow.svg'
import { Stack, Typography } from '@mui/material'

const NeedleTopLogo = () => {
    return (
        <Stack
            spacing={1}
            sx={{ position: 'fixed', top: '60px', left: '40px' }}
        >
            <NeedleLogoWithText />
            <Typography variant="body1">Your personal AI marketer.</Typography>
        </Stack>
    )
}

export default NeedleTopLogo

import { components } from 'react-select'
import { BrandsCardType } from '../../views/BrandProfilePage/type'
import { NeedleImage } from '../NeedleImage'
import { Product } from '../../utils/needleEnum'

export const uploadClick = (
    dataRef: React.RefObject<HTMLInputElement>
): void => {
    if (dataRef.current) {
        dataRef.current.click()
    }
}
export const handleUpload = (
    event: any,
    setData: React.Dispatch<React.SetStateAction<any>>
): void => {
    if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0]
        setData(file)
        event.target.value = null
    }
}

export const getMarkName = (type?: BrandsCardType): string => {
    switch (type) {
        case BrandsCardType.MultipleSelectDropdown:
            return 'Add markets'
        case BrandsCardType.ProductSelect:
            return 'Add products'
        case BrandsCardType.EIBasicAssets:
            return 'Upload Assets'
        default:
            return 'Add details'
    }
}

export const checkIsHaveData = (data: any, type?: BrandsCardType): boolean => {
    switch (type) {
        case BrandsCardType.EIAssets:
            return data.Link !== ''
        case BrandsCardType.EITemplatesEmail:
            return data.IsLinkEmail || data.FromScratch
        case BrandsCardType.EIBasicAssets:
            return data.Logo.length > 0
        case BrandsCardType.MultipleText:
            return data[0] !== ''
        case BrandsCardType.Customers:
            return data.Describe !== ''
        case BrandsCardType.MultipleSelectDropdown:
        case BrandsCardType.ProductSelect:
            return data.length > 0
        case BrandsCardType.Link:
            return data.link !== '' || data.accessMail.length > 0
        case BrandsCardType.Metrics:
            return data.marketSpend !== undefined
        case BrandsCardType.LinkAndMedia:
            return data.link !== '' || data.media.length > 0
        case BrandsCardType.Text:
        default:
            return data !== ''
    }
}

export const formatFileSize = (size: number): string => {
    if (size === 0) return '0 Bytes'
    const k = 1024
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB']
    const i = Math.floor(Math.log(size) / Math.log(k))
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
}

export const formatFileType = (type: string): string => {
    const typeMapping: { [key: string]: string } = {
        PDF: 'PDF',
        'JPG, PNG, GIF': 'Image',
        'TTF, OTF': 'Font',
    }
    return typeMapping[type] || 'Unknown'
}

export const handleChange = (event: any, setValue: any): void => {
    const newValue = event.target.value.replace(/,/g, '')
    if (/^\d*$/.test(newValue)) {
        setValue(parseInt(newValue))
    }
}

export const customStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        minHeight: '45px',
        height: '45px',
        borderColor: 'bg-dark-blue',
        borderRadius: '8px',
        boxShadow: 'none',
        '&:hover': {
            border: 'bg-black',
        },
    }),
    dropdownIndicator: (provided: any, state: any) => ({
        ...provided,
        paddingRight: '20px',
        color: 'black',
        svg: {
            height: '16px',
            width: '16px',
        },
    }),
    menuPortal: (provided: any) => ({
        ...provided,
        zIndex: 9999,
    }),
}

export const getIconUrl = (code: string): string => {
    return `https://flagcdn.com/${code.toLowerCase()}.svg`
}

export const CustomOptionNormal = (props: any) => {
    return (
        <components.Option {...props}>
            <div className="flex flex-row">
                <NeedleImage
                    src={getIconUrl(props.data.value)}
                    alt={props.data.value}
                    className="w-30px"
                />
                <div className="pl-10px flex flex-col">{props.data.label}</div>
            </div>
        </components.Option>
    )
}
export const CustomOptionBig = (props: any) => {
    return (
        <components.Option {...props}>
            <div className="flex flex-row">
                <NeedleImage
                    src={props.data.image}
                    alt=""
                    className="w-12 mr-10px rounded-md aspect-square"
                />
                <div className="flex flex-col justify-center">
                    {props.data.label}
                    {props.data.description && (
                        <div>— {props.data.description}</div>
                    )}
                </div>
            </div>
        </components.Option>
    )
}

export const formatNumber = (
    number: number | undefined
): string | undefined => {
    if (number) {
        return new Intl.NumberFormat().format(number)
    }
    return undefined
}
export function filterUniqueById(items: Product[]) {
    const seenIds = new Set()
    return items.filter((item) => {
        if (!seenIds.has(item.id)) {
            seenIds.add(item.id)
            return true
        }
        return false
    })
}

export const selectNewestFile = (
    newData: File | undefined | null,
    currentData: Needle.metaDataAssets | null
): File | Needle.metaDataAssets[] => {
    if (newData === undefined) {
        if (currentData) {
            return [currentData]
        } else {
            return []
        }
    }
    if (newData === null) {
        return []
    }
    return newData
}

export const selectNewestFiles = (
    newData: File[] | undefined | null,
    currentData: Needle.metaDataAssets[] | null
): (File | Needle.metaDataAssets)[] => {
    if (newData === undefined) {
        return currentData || []
    }
    if (newData === null) {
        return []
    }
    if (newData.length > 0) {
        return newData
    }
    return currentData || []
}

import React, { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../firebase/client'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import {
    fetchUserConnector,
    setConnectorsForNewUser,
} from '../redux/connectorReducer'
import { useAppDispatch } from '../redux/store'
import LoadingScreen from '../views/LoadingScreen/LoadingScreen'
import { Services } from '../services'
import { segmentIdentify } from '../services/segments'
import useSWRImmutable from 'swr/immutable'
import useUserProfile from '../hooks/useUserProfile'
import { CHECK_SHOPIFY_CONNECTOR_PATH } from '../views/OnBoardingPage/OnBoardingFillYourData/ConnectingYourShopifyConnector'
import AdminPreviewFloating from './AdminPreviewFloating'

type Props = {
    children: JSX.Element
}

function AuthGuard(props: Props) {
    const [user, loading] = useAuthState(auth)
    const [justLoggedOut, setJustLoggedOut] = useState<boolean>(false)
    useSWRImmutable(user ? `/segment-identify` : null, async () => {
        if (!user) return undefined
        const creationTimestamp = new Date(
            user.metadata.creationTime ?? 0
        ).getTime()
        const now = Date.now()
        const isNewUser = now - creationTimestamp < 20000
        if (isNewUser) return
        const profile = (await Services.getUserProfile()).data
        try {
            if (!searchParams.get('service')) {
                segmentIdentify({
                    user_id: user?.uid ?? '',
                    data: {
                        email: user?.email,
                    },
                })
            }
        } catch (e) {
            console.log('Segment tracking failed', e)
        }
        return profile
    })

    const { userProfile } = useUserProfile()
    const [searchParams, _] = useSearchParams()
    const dispatch = useAppDispatch()
    const [desiredDestination, setDesiredDestination] = useState<string | null>(
        null
    )
    const navigate = useNavigate()

    useEffect(() => {
        if (status === 'loading') return
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                const creationTimestamp = new Date(
                    user.metadata.creationTime ?? 0
                ).getTime()
                const now = Date.now()
                const isNewUser = now - creationTimestamp < 20000
                if (
                    isNewUser &&
                    !searchParams.get('n') &&
                    !Boolean(userProfile?.default_workspace)
                ) {
                    dispatch(setConnectorsForNewUser())
                } else {
                    dispatch(fetchUserConnector())
                }
                setJustLoggedOut(true)
            }
        })
        return unsubscribe
    }, [userProfile?.default_workspace])

    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        if (loading || (status === 'init' && user)) {
            setIsLoading(true)
        } else {
            setIsLoading(false)
        }
    }, [loading, user])

    if (isLoading) {
        return (
            <div className="h-screen">
                <LoadingScreen />
            </div>
        )
    }

    if (!user) {
        const desiredRoute = window.location.href.replace(
            window.location.origin,
            ''
        )
        // Store the desired route in localStorage
        if (desiredRoute !== '/login') {
            if (desiredRoute.includes(CHECK_SHOPIFY_CONNECTOR_PATH)) {
                localStorage.setItem(CHECK_SHOPIFY_CONNECTOR_PATH, desiredRoute)
            } else {
                if (!justLoggedOut) {
                    localStorage.setItem('desiredRoute', desiredRoute)
                }
            }
        }
        return <Navigate to={'/'} />
    }

    return (
        <>
            <AdminPreviewFloating />
            {props.children}
        </>
    )
}

export default AuthGuard

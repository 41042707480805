import React, { useState } from 'react'
import {
    SmartAITool,
    SmartAIToolType,
} from '../../components/CoverBox/SmartAITool'
import { CoverBox } from '../../components/CoverBox/CoverBox'
import {
    BRAND_CUSTOMIZATION_SECTIONS,
    LIST_SHOW_BRAND_CUSTOMIZATION,
} from './constants'
import { DropdownListView } from '../HomeScreen/DropdownListView'
import brandStore from '../../storeZustand/brandCustomizationStore'
import { usePageTitle } from '../HomeScreen/component'

function BrandProfilePage() {
    usePageTitle('Needle - Brand Profile')

    const [listShowState, setListShowState] = useState<boolean[]>(
        LIST_SHOW_BRAND_CUSTOMIZATION
    )
    const { brandPCount, customerCount, brandMCount, productToCount } =
        brandStore((state) => ({
            brandPCount: state.brandPCount,
            customerCount: state.customerCount,
            brandMCount: state.brandMCount,
            productToCount: state.productToCount,
        }))
    const listClear = [brandPCount, customerCount, brandMCount, productToCount]

    const setShowState = (isShow: boolean, index: number): void => {
        const newShowState = [...listShowState]
        newShowState[index] = isShow
        setListShowState(newShowState)
    }

    return (
        <div className="bg-beige h-full w-full transition-colors text-dark-blue">
            <div className="flex flex-col justify-center items-center w-full mb-14">
                <div className="flex flex-col w-4/5 sm:w-5/6 max-w-728px items-center">
                    <div className="flex w-full pb-2 flex-col justify-center items-center">
                        <SmartAITool
                            pageWord={`Brand Profile`}
                            typePage={SmartAIToolType.detailPage}
                        />
                        <div className="font-body-text pt-2">
                            These details help generate on-brand tone of voice
                            and outputs
                        </div>
                    </div>
                    {BRAND_CUSTOMIZATION_SECTIONS.map(
                        (brandsCustomizationGroup, index) => (
                            <div
                                key={brandsCustomizationGroup.name}
                                className="w-full"
                            >
                                <CoverBox
                                    name={brandsCustomizationGroup.name}
                                    connectStatus={{
                                        currentConnect: listClear[index],
                                        maxConnect:
                                            brandsCustomizationGroup
                                                .detailSection.length,
                                    }}
                                    index={index}
                                    showClick={setShowState}
                                    isDropDown={
                                        brandsCustomizationGroup.detailSection
                                            .length > 1
                                    }
                                    keyFinish="Completed"
                                    isShowView={listShowState[index]}
                                >
                                    <DropdownListView
                                        itemList={
                                            brandsCustomizationGroup.detailSection
                                        }
                                        isShowView={listShowState[index]}
                                        isHoverAnimated={false}
                                        showClick={(isShow: boolean) =>
                                            setShowState(isShow, index)
                                        }
                                    />
                                </CoverBox>
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    )
}

export default BrandProfilePage

import { useState, useRef, useEffect } from 'react'
import { Field } from '../../../utils/needleEnum'
import UploadFile from '../../../components/DetailCardView/UploadFile'
import { uploadClick } from '../../../components/DetailCardView/component'
import CheckBoxNeedle from '../../../components/CheckBoxNeedle'
import ideasStore from '../../../storeZustand/ideasStore'
import { NeedleButton, ButtonType } from '../../../components/NeedleButton'
import { uploadFileToFirebaseStoragePromise } from '../../../firebase/storage'
import { IdeaDetailServices } from '../../../services/IdeaDetail/ideadetail.services'
import globalErrorDialogStore from '../../../storeZustand/globalErrorDialogStore'
import { useAppDispatch } from '../../../redux/store'
import { showToast } from '../../../redux/appReducer'

interface RequiredInformationFormProps {
    requiredInformation: Field[]
    ideaId: string
    onSubmitSuccess?: () => void
}

export const RequiredInformationForm = ({
    requiredInformation,
    ideaId,
    onSubmitSuccess,
}: RequiredInformationFormProps) => {
    const [formData, setFormData] = useState<Record<string, any>>(() => {
        const initialData: Record<string, any> = {}
        if (!requiredInformation) return initialData
        requiredInformation.forEach((field) => {
            initialData[field.title] = field.value || ''
        })
        return initialData
    })

    const dispatch = useAppDispatch()

    const [formErrors, setFormErrors] = useState<Record<string, string>>({})
    const [isSubmitAttempted, setIsSubmitAttempted] = useState(false)
    const { currency } = ideasStore((state) => ({
        currency: state.currency,
    }))
    const [isValid, setIsValid] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        validateForm()
    }, [formData, isSubmitAttempted])

    const validateForm = () => {
        const errors: Record<string, string> = {}
        let formIsValid = true
        if (!requiredInformation) return
        requiredInformation.forEach((field) => {
            const value = formData[field.title]
            if (!value || (Array.isArray(value) && value.length === 0)) {
                errors[field.title] = 'This field is required'
                formIsValid = false
            }
        })

        setFormErrors(errors)
        setIsValid(formIsValid)
        return formIsValid
    }

    const handleInputChange = (fieldTitle: string, value: any) => {
        setFormData((prev) => ({
            ...prev,
            [fieldTitle]: value,
        }))

        if (isSubmitAttempted && formErrors[fieldTitle]) {
            setFormErrors((prev) => {
                const newErrors = { ...prev }
                delete newErrors[fieldTitle]
                return newErrors
            })
        }

        setIsSubmitAttempted(false)
    }

    const handleSubmit = async () => {
        setIsSubmitAttempted(true)
        const isFormValid = validateForm()

        if (isFormValid) {
            setIsLoading(true)
            try {
                const updatedFields = await Promise.all(
                    requiredInformation.map(async (field) => {
                        if (
                            field.type === 'file_upload' &&
                            formData[field.title]?.[0] instanceof File
                        ) {
                            const file = formData[field.title][0]
                            const url =
                                await uploadFileToFirebaseStoragePromise({
                                    file,
                                    fileName: file.name,
                                    bucketName:
                                        '/brand-assets/required-information',
                                })
                            return {
                                ...field,
                                value: url,
                            }
                        }
                        return {
                            ...field,
                            value: formData[field.title],
                        }
                    })
                )

                await IdeaDetailServices.updateRequiredInformation(ideaId, {
                    required_information: updatedFields,
                })

                dispatch(
                    showToast({
                        type: 'success',
                        content: 'Required information submitted!',
                    })
                )

                onSubmitSuccess?.()
            } catch (error) {
                const { setIsOpenErrorDialog } =
                    globalErrorDialogStore.getState()
                setIsOpenErrorDialog(true)
            } finally {
                setIsLoading(false)
            }
        }
    }

    const renderField = (field: Field, index: number) => {
        switch (field.type) {
            case 'small_text':
                return (
                    <div className="flex flex-col gap-2">
                        <div className="font-subtitle">{field.title}</div>
                        <div className="flex flex-col gap-1">
                            <div className="font-small-text">
                                {field.secondary_text}
                            </div>
                            <textarea
                                rows={1}
                                placeholder={field.placeholder}
                                className="mt-1 border border-beige-outline font-body-text py-3 px-4 w-full rounded-lg focus:outline-dark-blue"
                                value={formData[field.title] || ''}
                                onChange={(e) =>
                                    handleInputChange(
                                        field.title,
                                        e.target.value
                                    )
                                }
                            />
                        </div>
                    </div>
                )

            case 'large_text':
                return (
                    <div className="flex flex-col gap-2">
                        <div className="font-subtitle">{field.title}</div>
                        <div className="flex flex-col gap-1">
                            <div className="font-small-text">
                                {field.secondary_text}
                            </div>
                            <textarea
                                placeholder={field.placeholder}
                                className="mt-1 border border-beige-outline font-body-text py-3 px-4 w-full rounded-lg focus:outline-dark-blue min-h-[100px]"
                                value={formData[field.title] || ''}
                                onChange={(e) =>
                                    handleInputChange(
                                        field.title,
                                        e.target.value
                                    )
                                }
                            />
                        </div>
                    </div>
                )

            case 'currency':
            case 'percentage':
                return (
                    <div className="flex flex-col gap-2">
                        <div className="font-subtitle">{field.title}</div>
                        <div className="flex flex-col gap-1">
                            <div className="font-small-text">
                                {field.secondary_text}
                            </div>
                            <div className="flex flex-row pt-2 text-black font-normal leading-130 text-base item-center">
                                {field.type === 'currency' && (
                                    <div className="bg-beige border-t border-l border-b border-beige-outline py-3 px-4 rounded-s-lg text-opacity-50 text-black">
                                        {currency}
                                    </div>
                                )}
                                <input
                                    type="text"
                                    value={formData[field.title] || ''}
                                    placeholder={field.placeholder}
                                    onChange={(e) =>
                                        handleInputChange(
                                            field.title,
                                            e.target.value
                                        )
                                    }
                                    className={`py-3 px-4 border ${
                                        field.type === 'currency'
                                            ? 'rounded-e-lg w-140px'
                                            : 'rounded-s-lg w-60px'
                                    } focus:ring-1 focus:ring-inset focus:ring-dark-blue`}
                                />
                                {field.type === 'percentage' && (
                                    <div className="bg-beige border-t border-r border-b border-beige-outline py-3 px-4 rounded-e-lg text-opacity-50 text-black">
                                        %
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )

            case 'multi_select':
                return (
                    <div className="flex flex-col gap-2">
                        <div className="font-subtitle">{field.title}</div>
                        <div className="flex flex-col gap-1">
                            <div className="font-small-text">
                                {field.secondary_text}
                            </div>
                            <div className="flex flex-col pt-2">
                                {field.options?.map((option) => (
                                    <CheckBoxNeedle
                                        key={option.value}
                                        keyName={option.label}
                                        selectedData={
                                            Array.isArray(formData[field.title])
                                                ? formData[field.title]
                                                : []
                                        }
                                        setData={(newValues) =>
                                            handleInputChange(
                                                field.title,
                                                newValues
                                            )
                                        }
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                )

            case 'dropdown':
                return (
                    <div className="flex flex-col gap-2">
                        <div className="font-subtitle">{field.title}</div>
                        <div className="flex flex-col gap-1">
                            <div className="font-small-text">
                                {field.secondary_text}
                            </div>
                            <select
                                className="mt-1 border border-beige-outline font-body-text py-3 px-4 w-full rounded-lg focus:outline-dark-blue"
                                value={(formData[field.title] as string) || ''}
                                onChange={(e) =>
                                    handleInputChange(
                                        field.title,
                                        e.target.value
                                    )
                                }
                            >
                                <option value="">Select an option</option>
                                {field.options?.map((option) => (
                                    <option
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                )

            case 'file_upload': {
                const fileRef = useRef<HTMLInputElement>(null)
                const acceptedTypes =
                    field.accepted_file_types?.map(
                        (type) => `.${type.toLowerCase()}`
                    ) || []

                return (
                    <div className="flex flex-col gap-2">
                        <div className="font-subtitle">{field.title}</div>
                        <div className="flex flex-col gap-1">
                            <div className="font-small-text">
                                {field.secondary_text}
                            </div>
                            <UploadFile
                                typeOfFile={
                                    field.accepted_file_types?.join(', ') || ''
                                }
                                acceptsType={acceptedTypes}
                                handleUpload={() => uploadClick(fileRef)}
                                data={formData[field.title] || null}
                                setData={(files) =>
                                    handleInputChange(field.title, files)
                                }
                                isMust={true}
                                imageData={
                                    <img
                                        alt="file-upload"
                                        className="w-12 aspect-square"
                                        src="/assets/icons/file-upload-icon.svg"
                                    />
                                }
                            />

                            <input
                                type="file"
                                ref={fileRef}
                                onChange={(e) => {
                                    const files = Array.from(
                                        e.target.files || []
                                    )
                                    handleInputChange(
                                        field.title,
                                        files.length > 0 ? [files[0]] : null
                                    )
                                }}
                                style={{ display: 'none' }}
                                accept={acceptedTypes.join(',')}
                            />
                        </div>
                    </div>
                )
            }

            case 'url': {
                const urls = Array.isArray(formData[field.title])
                    ? formData[field.title]
                    : formData[field.title]
                      ? [formData[field.title]]
                      : ['']

                return (
                    <div className="flex flex-col gap-2">
                        <div className="font-subtitle">{field.title}</div>
                        <div className="flex flex-col gap-1">
                            <div className="font-small-text">
                                {field.secondary_text}
                            </div>
                            <div className="flex flex-col gap-3">
                                {urls.map((url: string, urlIndex: number) => (
                                    <div
                                        key={urlIndex}
                                        className="flex items-center gap-2"
                                    >
                                        <input
                                            type="url"
                                            placeholder={field.placeholder}
                                            className="mt-1 border border-beige-outline font-body-text py-3 px-4 w-full rounded-lg focus:outline-dark-blue"
                                            value={url}
                                            onChange={(e) => {
                                                const newUrls = [...urls]
                                                newUrls[urlIndex] =
                                                    e.target.value
                                                handleInputChange(
                                                    field.title,
                                                    newUrls
                                                )
                                            }}
                                        />
                                        {urls.length > 1 && (
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    const newUrls = urls.filter(
                                                        (
                                                            _: string,
                                                            index: number
                                                        ) => index !== urlIndex
                                                    )
                                                    handleInputChange(
                                                        field.title,
                                                        newUrls
                                                    )
                                                }}
                                                className="p-2 hover:bg-gray-50 rounded-full"
                                            >
                                                <img
                                                    src="/assets/icons/close-icon.svg"
                                                    alt="delete"
                                                    className="w-4 h-4"
                                                />
                                            </button>
                                        )}
                                    </div>
                                ))}
                                <div className="flex justify-center">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            const newUrls = [...urls, '']
                                            handleInputChange(
                                                field.title,
                                                newUrls
                                            )
                                        }}
                                        className="border border-dark-blue text-dark-blue px-3 py-2.5 rounded-lg hover:bg-gray-50 font-button-text"
                                    >
                                        Add
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            default:
                return null
        }
    }

    return (
        <div>
            <div className="font-header-3">
                Please provide additional information
            </div>
            <div className="font-body-text pt-2 pb-6">
                We need this information to get started:
            </div>
            <div className="space-y-4">
                {requiredInformation ? (
                    requiredInformation.map((field, index) => (
                        <div key={field.title}>
                            {renderField(field, index)}
                            {isSubmitAttempted && formErrors[field.title] && (
                                <div className="text-red-theme text-sm mt-1">
                                    {formErrors[field.title]}
                                </div>
                            )}
                        </div>
                    ))
                ) : (
                    <></>
                )}
            </div>
            <div className="flex justify-end">
                <div>
                    <NeedleButton
                        onClickFunction={handleSubmit}
                        buttonCharacter={
                            isLoading ? 'Submitting...' : 'Submit information'
                        }
                        buttonType={ButtonType.Black}
                        isDisable={(isSubmitAttempted && !isValid) || isLoading}
                    />
                </div>
            </div>
        </div>
    )
}

export default RequiredInformationForm

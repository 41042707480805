import React, { useEffect, useRef, useState } from 'react'
import { ActionData, EventType } from '../HomeScreen/constants'
import ImageListDropDown from './ImageListDropDown'
import { IMAGE_CARD_HEIGHT, SUB_CARD_MARGIN } from './constants'
import { HtmlToReact } from '../../utils/utils'
import { SubItem } from '../../components/DetailCardView/SubItem'
import './IdeasPage.scss'
import { useNavigate } from 'react-router-dom'
import { Product } from '../../utils/needleEnum'
import { PATH } from '../../constants/path'
import { filterUniqueById } from '../../components/DetailCardView/component'

interface IdeasItemProps {
    idea: ActionData
    index: number
    isArchive?: boolean
}
export const IdeasItem = ({
    idea,
    index,
    isArchive = false,
}: IdeasItemProps) => {
    const [groupImage, setGroupImage] = useState<Product[]>(
        idea.metadata?.products ? filterUniqueById(idea.metadata.products) : []
    )
    const [groupImageList, setGroupImageList] = useState<Product[][]>([[]])
    const isHaveImage = groupImage.length > 0
    const numberOfAddLine = Math.ceil(groupImage.length / 4) - 1
    const [isHovered, setIsHovered] = useState(false)
    const containerRef = useRef<HTMLDivElement>(null)
    const [defaultHeight, setDefaultHeight] = useState<number | undefined>(
        undefined
    )
    const [isVisible, setIsVisible] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        setGroupImage(
            idea.metadata?.products
                ? filterUniqueById(idea.metadata.products)
                : []
        )
        const filterGroupImage = filterUniqueById(groupImage)
        const newGroupImageList: Product[][] = []
        let smallerGroupImage: Product[] = []
        filterGroupImage.forEach((image, index) => {
            smallerGroupImage.push(image)
            if ((index + 1) % 4 === 0) {
                newGroupImageList.push(smallerGroupImage)
                smallerGroupImage = []
            }
        })
        if (smallerGroupImage.length > 0) {
            newGroupImageList.push(smallerGroupImage)
        }
        setGroupImageList(newGroupImageList)
    }, [idea])

    useEffect(() => {
        setIsVisible(true)
    }, [])

    useEffect(() => {
        if (containerRef.current && isHaveImage) {
            const currentHeight = containerRef.current.offsetHeight
            if (defaultHeight === undefined) {
                setDefaultHeight(currentHeight)
                containerRef.current.style.height = `${currentHeight}px`
                return
            }
            if (!isHovered) {
                containerRef.current.style.height = `${defaultHeight}px`
            } else {
                containerRef.current.style.height = `${
                    defaultHeight +
                    numberOfAddLine * (IMAGE_CARD_HEIGHT + SUB_CARD_MARGIN)
                }px`
            }
        }
    }, [isHovered])

    return (
        <div
            className={`rounded-lg p-6 mt-6 cursor-pointer shadow-click-card
            flex transition-all duration-300 bg-white overflow-hidden flex-col
            hover:shadow-click-card-hover hover:-translate-y-1 relative ${isVisible ? 'fade-in' : ' opacity-0'}`}
            style={{ animationDelay: `${index * 0.3}s` }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => navigate(PATH.ideasDetail.replace(':id', idea.id))}
            ref={containerRef}
        >
            <div className="flex flex-row">
                <div className="font-header-3">{HtmlToReact(idea.name)}</div>
                <div className={`${isArchive && 'pl-3'}`}>
                    <SubItem
                        event={isArchive ? EventType.Archived : EventType.Ideas}
                    />
                </div>
            </div>
            <div
                className="font-body-text pt-2"
                dangerouslySetInnerHTML={{ __html: idea.description }}
            />
            <div>
                {groupImageList.length > 0 ? (
                    <ImageListDropDown
                        isDropdown={isHovered}
                        groupImage={groupImageList}
                    />
                ) : (
                    <div className="flex flex-row h-9 mt-3" />
                )}
            </div>
            {/* <div className={`absolute bottom-6 right-6`}>
                <ListToolsConnect listTools={idea.metadata} isVertical={true} />
            </div> */}
        </div>
    )
}

export default IdeasItem

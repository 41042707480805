import {
    Box,
    Button,
    CircularProgress,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import React, { useState } from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import useSWR from 'swr'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../redux/store'
import { Services } from '../../services'
import { fetchUserConnector } from '../../redux/connectorReducer'
import { PATH } from '../../constants/path'
import { StyleCheckboxFill } from '../../components/StyledCheckBox'
import useSWRMutation from 'swr/mutation'

const ConnectFacebookScreen = () => {
    const [accessToken, setAccessToken] = React.useState<string>()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { data: adaccounts, isLoading: isLoadingAdAccount } = useSWR(
        accessToken && accessToken !== 'loading'
            ? 'Get facebook assets'
            : undefined,
        async () => {
            return await Services.getFacebookAssets()
        }
    )

    const [selected, setSelected] = useState<string>('user')

    const AgenciesOption = () => {
        return (
            <Stack gap={0.5} sx={{ maxWidth: '400px' }}>
                <Box>
                    <Typography variant="body1-bold">Access Token</Typography>
                    <TextField
                        size="small"
                        fullWidth
                        sx={{ height: '40px !important' }}
                    />
                </Box>
                <Box>
                    <Button>Validate system user access token</Button>
                </Box>
            </Stack>
        )
    }

    const UserOption = () => {
        const handleOAuthResponse = async (response: any) => {
            setAccessToken('loading')
            const shortLivedAccessToken = response.accessToken
            const longLiveAccessToken =
                await Services.getFacebookLongLivedToken(shortLivedAccessToken)
            setAccessToken(longLiveAccessToken.access_token)
        }
        return (
            <Box mt={2} display={'flex'} alignItems={'center'} gap={1}>
                <FacebookLogin
                    appId="2439979926206277"
                    fields="name,email,picture"
                    scope="ads_read,business_management,ads_management"
                    onClick={() => {}}
                    callback={handleOAuthResponse}
                    render={(renderProps) => (
                        <Button
                            onClick={renderProps.onClick}
                            disableRipple
                            sx={{
                                '&:hover': {
                                    bgcolor: '#1877F2',
                                },
                                bgcolor: '#1877F2',
                                color: 'white',
                            }}
                        >
                            Continue with Facebook
                        </Button>
                    )}
                />
                {Boolean(accessToken) ? (
                    accessToken === 'loading' ? (
                        <CircularProgress size={16} color="info" />
                    ) : (
                        <Typography
                            sx={{ color: 'green.600' }}
                            variant="body2-bold"
                        >
                            {' '}
                            Authorized
                        </Typography>
                    )
                ) : (
                    <Typography variant="body2">
                        {' '}
                        Click to authorize API
                    </Typography>
                )}
            </Box>
        )
    }

    const ConnectorConfigSection = () => {
        const { trigger, isMutating } = useSWRMutation(
            'Trigger create connector',
            async () => {
                await handleConnectFacebookAds()
                dispatch(fetchUserConnector())
                navigate(PATH.tools)
            }
        )
        const handleConnectFacebookAds = async () => {
            await Services.createConnector({
                service: 'facebook_ads',
                config: {
                    sync_mode: syncMode,
                    accounts: selectedAccounts,
                },
                auth: {
                    access_token: accessToken,
                },
            })
        }

        enum SyncMode {
            All_Accounts = 'AllAccounts',
            Specific_Accounts = 'SpecificAccounts',
        }
        const handleChangeSyncMode = (event: SelectChangeEvent<SyncMode>) => {
            setSyncMode(event.target.value as SyncMode)
        }
        const [syncMode, setSyncMode] = useState<SyncMode>(
            SyncMode.All_Accounts
        )
        const [selectedAccounts, setSelectedAcounts] = useState<string[]>([])
        const handleCheckboxChange = (optionValue: string) => {
            setSelectedAcounts((selectedAccounts) => {
                if (selectedAccounts.includes(optionValue)) {
                    return selectedAccounts.filter(
                        (item) => item !== optionValue
                    )
                }
                return [...selectedAccounts, optionValue]
            })
        }
        return (
            <Stack spacing={1}>
                <Box width={'100%'}>
                    <Typography variant="body1-bold">
                        Account sync mode
                    </Typography>
                    <Box>
                        <Select
                            value={syncMode}
                            sx={{ width: '400px' }}
                            onChange={handleChangeSyncMode}
                            input={<OutlinedInput />}
                        >
                            {Object.entries(SyncMode).map(([key, value]) => (
                                <MenuItem key={value} value={value}>
                                    {key.replaceAll('_', ' ')}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Box>
                {syncMode === SyncMode.Specific_Accounts && (
                    <Box>
                        {isLoadingAdAccount && (
                            <CircularProgress size={20} color="info" />
                        )}
                        {adaccounts && (
                            <FormGroup>
                                {adaccounts?.map(
                                    (account: { name: string; id: string }) => (
                                        <FormControlLabel
                                            key={account.id}
                                            control={
                                                <StyleCheckboxFill
                                                    disabled={isMutating}
                                                    checked={selectedAccounts.includes(
                                                        account.id
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            account.id
                                                        )
                                                    }
                                                />
                                            }
                                            sx={{
                                                fontFamily: 'Work Sans',
                                                fontWeight: '400',
                                                '& .MuiFormControlLabel-label':
                                                    {
                                                        fontSize: '16px',
                                                    },
                                                marginLeft: '0px',
                                            }}
                                            label={`${account.name} (${account.id})`}
                                        />
                                    )
                                )}
                            </FormGroup>
                        )}
                    </Box>
                )}
                <Box>
                    <Button
                        disabled={isMutating}
                        startIcon={
                            isMutating ? (
                                <CircularProgress color="info" size={18} />
                            ) : undefined
                        }
                        onClick={() => trigger()}
                    >
                        Save & Test
                    </Button>
                </Box>
            </Stack>
        )
    }

    return (
        <Container
            sx={{
                bgcolor: 'white',
                minHeight: '100vh',
                p: 5,
            }}
        >
            <Stack spacing={2}>
                <Box display={'flex'} alignItems={'center'} gap={1}>
                    <img
                        height={48}
                        width={48}
                        alt="facebook"
                        src="/assets/svgs/facebook.svg"
                    />
                    <Typography variant="h3">Facebook Ads</Typography>
                </Box>
                <Box>
                    <FormControl>
                        <RadioGroup
                            className="gap-4"
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={selected}
                            onChange={(e) => {
                                setSelected(e.target.value as any)
                            }}
                            name="radio-buttons-group"
                        >
                            {[
                                {
                                    id: 'user',
                                    title: 'Grant User Access',
                                    subtitle: 'For most Facebook Ads Users',
                                },
                                {
                                    id: 'agency',
                                    title: 'Use System User Token',
                                    subtitle: 'For Agencies',
                                },
                            ]?.map((invitation) => {
                                return (
                                    <FormControlLabel
                                        key={invitation.id}
                                        sx={{
                                            marginLeft: 0,
                                            marginRight: 0,
                                        }}
                                        className={`px-2 py-4 rounded-lg border border-[${
                                            invitation.id == selected
                                                ? '#404040'
                                                : '#D4D4D4'
                                        }]`}
                                        value={invitation.id}
                                        control={
                                            <Radio
                                                disabled={
                                                    invitation.id === 'agency'
                                                }
                                                size="small"
                                            />
                                        }
                                        label={
                                            <Stack
                                                spacing={1.5}
                                                direction={'row'}
                                            >
                                                <Box ml={0.5}>
                                                    <h2 className="font-normal text-base mb-[2px]">
                                                        {invitation?.title}
                                                    </h2>
                                                    <p className="text-sm font-normal text-[#00000080]">
                                                        Invited by{' '}
                                                        {invitation?.subtitle}
                                                    </p>
                                                </Box>
                                            </Stack>
                                        }
                                    />
                                )
                            })}
                        </RadioGroup>
                    </FormControl>

                    {selected === 'user' ? <UserOption /> : <AgenciesOption />}
                </Box>
                {(accessToken ?? 'loading') !== 'loading' && (
                    <ConnectorConfigSection />
                )}
            </Stack>
        </Container>
    )
}

export default ConnectFacebookScreen

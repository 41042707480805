import React from 'react'
import { Box, Popover } from '@mui/material'
import { logOut } from '../../redux/authReducer'
import { useAppDispatch } from '../../redux/store'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase/client'
import { NeedleImage } from '../../components/NeedleImage'
import './BottomPartNavigation.css'
import { useNavigate } from 'react-router-dom'
import { PATH } from '../../constants/path'
import { userBackground } from './component'

interface BottomPartNavigationProps {
    currentUser: Needle.User
}

export default function BottomPartNavigation({
    currentUser,
}: BottomPartNavigationProps) {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [user] = useAuthState(auth)
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const open = Boolean(anchorEl)
    const id = open ? 'popover-user' : undefined

    return (
        <Box>
            <div
                className="hover:shadow-button-secondary transition-all duration-300 rounded rounded-s border-1px cursor-pointer 
            mb-6 mx-8 p-3 mt-4 refer-friend-button border-beige-outline justify-center"
            >
                <p
                    className="text-dark-blue font-sans text-base font-semibold text-center caret-transparent"
                    onClick={() => {
                        window.open(
                            'https://forms.askneedle.com/refer-a-friend',
                            '_blank'
                        )
                    }}
                >
                    Refer a friend 🥰
                </p>
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                sx={{
                    marginLeft: '10px',
                }}
            >
                <div className="font-header-3 relative rounded-lg w-200px shadow-un-click-card">
                    <div className="py-3">
                        <div
                            className={`pt-3 pl-4 pb-3 cursor-pointer flex flex-row gap-2 hover:bg-beige`}
                            onClick={() => {
                                navigate(PATH.settingsPage)
                                setAnchorEl(null)
                            }}
                        >
                            <img
                                src="/assets/icons/user-settings-icon.png"
                                alt="user-settings-icon"
                                className="h-6 aspect-square"
                            />
                            <div>Settings</div>
                        </div>
                        <div
                            className="pt-3 pb-3 pl-4 cursor-pointer flex flex-row gap-2 hover:bg-beige"
                            onClick={() => {
                                navigate(PATH.creditPage)
                                setAnchorEl(null)
                            }}
                        >
                            <img
                                src="/assets/icons/credits-icon.png"
                                alt="credits-icon"
                                className="h-6 aspect-square"
                            />
                            <div>Credits</div>
                        </div>
                    </div>
                    <div className="bg-beige-outline h-1px absolute left-0 w-full"></div>
                    <div
                        className="p-4 cursor-pointer flex flex-row gap-2 hover:bg-beige"
                        onClick={() => dispatch(logOut())}
                    >
                        <img
                            src="/assets/icons/logout-icon.png"
                            alt="logout-icon"
                            className="h-6 aspect-square"
                        />
                        <div>Logout</div>
                    </div>
                </div>
            </Popover>
            <div
                onClick={handleClick}
                aria-describedby={id}
                className={`cursor-pointer caret-transparent relative ${anchorEl && 'bg-beige'} hover:bg-beige transition-all duration-300`}
            >
                <div className="flex flex-col w-full">
                    <p className="h-px w-full bg-beige-outline" />
                    <div className="bottom-action flex flex-row items-center justify-between">
                        {user &&
                            (user.photoURL ? (
                                <NeedleImage
                                    src={user.photoURL}
                                    alt="user page icon"
                                    className={'h-9 aspect-square rounded-full'}
                                />
                            ) : (
                                <div
                                    className={`w-8 aspect-square ${userBackground(currentUser.profile?.first_name[0])} rounded-full 
                                font-user-avatar justify-center items-center text-center flex text-white`}
                                >
                                    {currentUser.profile?.first_name[0]}
                                </div>
                            ))}
                        <p className="text-dark-blue font-bold text-base font-sans pl-3 flex-1 caret-transparent">
                            {currentUser.profile?.first_name
                                ? currentUser.profile.first_name
                                : 'New user'}
                        </p>

                        <NeedleImage
                            src="/assets/svgs/user-page-icon.svg"
                            alt="user page icon"
                        />
                    </div>
                </div>
            </div>
        </Box>
    )
}

import React from 'react'
import { handleDragOver } from '../../views/AddAnIdeaPage/function'

interface UploadFileProps {
    typeOfFile: string
    name?: string
    acceptsType: string[]
    handleUpload: () => void
    data: File[] | Needle.metaDataAssets[] | null
    setData: React.Dispatch<React.SetStateAction<any>>
    isMust?: boolean
    imageData?: React.ReactNode
    multiple?: boolean
}

function UploadFile({
    typeOfFile,
    name,
    data,
    acceptsType,
    setData,
    handleUpload,
    isMust = false,
    imageData,
    multiple = false,
}: UploadFileProps) {
    const onDropEvent = (event: React.DragEvent<HTMLDivElement>): void => {
        event.preventDefault()
        event.stopPropagation()
        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            const files = Array.from(event.dataTransfer.files)
            const validFiles = files.filter((file) => {
                const fileExtension =
                    file.name.split('.').pop()?.toLowerCase() || ''
                return (
                    acceptsType.includes(file.type) ||
                    acceptsType.includes(fileExtension)
                )
            })
            if (multiple) {
                setData((prevData: File[]) => [...prevData, ...validFiles])
            } else {
                setData(validFiles[0] || null)
            }
        }
    }

    const removeFile = (index: number) => {
        if (multiple && Array.isArray(data)) {
            if (index === 0 && data.length === 1) {
                setData([])
                return
            }
            const state = [...data]
            state.splice(index, 1)
            setData(state)
        } else {
            setData([])
        }
    }

    const renderFileItem = (
        file: File | Needle.metaDataAssets,
        index: number
    ) => {
        const sizeInMB = (file.size / (1024 * 1024)).toFixed(1) + 'MB'

        let fileType
        const extension = file?.type?.split('/')?.pop()?.toLowerCase()

        switch (extension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
                fileType = 'Image'
                break
            case 'gif':
                fileType = 'GIF'
                break
            case 'pdf':
                fileType = 'PDF'
                break
            case 'ttf':
                fileType = 'Truetype Font'
                break
            case 'otf':
                fileType = 'Opentype Font'
                break
            default:
                fileType = null
        }

        const showDetails = file.size && fileType

        return (
            file && (
                <div
                    key={index}
                    className="flex flex-row items-center justify-between border border-beige-outline rounded-lg mb-2"
                >
                    <div className="flex flex-row font-body-text py-2 items-center">
                        <div className="ml-2 w-12">{imageData}</div>
                        <div className="flex flex-col pl-4">
                            <div className="truncate lg:max-w-md max-w-sm">
                                {file instanceof File ? file.name : file.label}
                            </div>
                            {showDetails && (
                                <div className="font-small-text opacity-50 pt-1">
                                    {sizeInMB} | {fileType}
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        className="flex flex-row justify-center items-center cursor-pointer px-4"
                        onClick={() => removeFile(index)}
                    >
                        <img
                            alt="close-icon"
                            className="w-6 aspect-square"
                            src="/assets/icons/close-icon.svg"
                        />
                    </div>
                </div>
            )
        )
    }

    return (
        <div>
            {name && (
                <div>
                    {name}
                    {isMust && <span className="text-red-theme">*</span>}
                </div>
            )}

            <div className="mt-2 mb-6">
                {data && (Array.isArray(data) ? data.length > 0 : data) ? (
                    <div>
                        {Array.isArray(data)
                            ? data.map((file, index) =>
                                  renderFileItem(file, index)
                              )
                            : renderFileItem(data, 0)}
                        {multiple && (
                            <button
                                className="px-3 py-1 border-black border text-black rounded"
                                onClick={handleUpload}
                            >
                                Add More
                            </button>
                        )}
                    </div>
                ) : (
                    <div
                        className="rounded-lg border-dashed border-2 outline-beige-outline flex justify-center w-full items-center"
                        onClick={handleUpload}
                        onDrop={onDropEvent}
                        onDragOver={handleDragOver}
                    >
                        <div className="text-dark-blue items-center justify-center flex flex-col py-8">
                            <img
                                alt="logo-brand"
                                className="w-12"
                                src="/assets/icons/add-file-icon.svg"
                            />
                            <div className="font-body-text pt-2">
                                Drag and drop or click to upload files
                            </div>
                            <div className="font-small-text pt-1 opacity-50">
                                Accepted file types: {typeOfFile}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default UploadFile

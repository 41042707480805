import React, { useEffect, useState } from 'react'
import brandStore, {
    BrandStoreProps,
} from '../../storeZustand/brandCustomizationStore'
import CardTypeText from './CardTypeText'
import CardTypeMultipleText from './CardTypeMultipleText'
import CardTypeCustomers from './CardTypeCustomers'
import CardTypeSelectDropDown from './CardTypeSelectDropDown'
import DataDisplayCard from './DataDisplayCard'
import { checkIsHaveData, getMarkName } from './component'
import CardTypeBrandAssets from './CardTypeBrandAssets'
import Lottie from 'react-lottie'
import CardTypeLink from './CardTypeLink'
import { BrandsCardType } from '../../views/BrandProfilePage/type'
import CardTypeLinkAndMedia from './CardTypeLinkAndMedia'
import { doneIconAnimation } from '../LottieAnimation'
import CardTypeMetrics from './CardTypeMetrics'
import { uploadFileToFirebaseStoragePromise } from '../../firebase/storage'
import useWorkspace from '../../hooks/useWorkspace'
import { EnhanceYourAIServices } from '../../services/EnhanceYourAI/enhanceyourai.services'
import { ButtonType, NeedleButton } from '../NeedleButton'
import ideasStore from '../../storeZustand/ideasStore'
import globalErrorDialogStore from '../../storeZustand/globalErrorDialogStore'
interface GetDataCardProps {
    card: string
    description?: string
    placeHolder?: string
    stateKey: keyof BrandStoreProps
    type?: BrandsCardType
    onFirstItemEditClick?: (() => void) | null
}

function GetDataCard({
    card,
    description,
    stateKey,
    type,
    placeHolder,
    onFirstItemEditClick,
}: GetDataCardProps) {
    const data: any = brandStore((state) => state[stateKey])
    const setValue = brandStore((state) => state.setValue)
    const isLink = type === BrandsCardType.Link
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [currentEdit, setCurrentEdit] = useState<any>(data)
    const [isDisable, setIsDisable] = useState<boolean>(true)
    const [isDisplayNeedleHelp, setIsDisplayNeedleHelp] =
        useState<boolean>(false)
    const { data: workspace } = useWorkspace()
    const isHaveData = checkIsHaveData(data, type)
    const { currency } = ideasStore((state) => ({
        currency: state.currency,
    }))

    const handleUpdateData = async (data: any) => {
        try {
            setLoading(true)
            const brand_name = data.Name
            const modifyData = {
                logo: data.Logo,
                guidelines: data.Guide,
                fonts: data.Font,
                no_guidelines: data.noGuide,
                no_fonts: data.noFont,
            }

            // Helper function to upload multiple files
            const uploadMultipleFiles = async (
                files: File[],
                bucketName: string
            ) => {
                const uploadedFiles = await Promise.all(
                    files.map(async (file) => {
                        const url = await uploadFileToFirebaseStoragePromise({
                            file,
                            fileName: file.name,
                            bucketName,
                        })
                        return {
                            url,
                            label: file.name,
                            size: file.size,
                            type: file.type,
                        }
                    })
                )
                return uploadedFiles
            }

            // Handle logo uploads
            if (
                Array.isArray(modifyData.logo) &&
                modifyData.logo.some((item) => item instanceof File)
            ) {
                const logoFiles = modifyData.logo.filter(
                    (item) => item instanceof File
                )
                modifyData.logo = await uploadMultipleFiles(
                    logoFiles,
                    `${workspace?.workspace_id}/brand-assets/basic-assets`
                )
            }

            // Handle guidelines uploads
            if (
                Array.isArray(modifyData.guidelines) &&
                modifyData.guidelines.some((item) => item instanceof File)
            ) {
                const guidelineFiles = modifyData.guidelines.filter(
                    (item) => item instanceof File
                )
                modifyData.guidelines = await uploadMultipleFiles(
                    guidelineFiles,
                    `${workspace?.workspace_id}/brand-assets/basic-assets`
                )
            }

            // Handle fonts uploads
            if (
                Array.isArray(modifyData.fonts) &&
                modifyData.fonts.some((item) => item instanceof File)
            ) {
                const fontFiles = modifyData.fonts.filter(
                    (item) => item instanceof File
                )
                modifyData.fonts = await uploadMultipleFiles(
                    fontFiles,
                    `${workspace?.workspace_id}/brand-assets/basic-assets`
                )
            }

            await EnhanceYourAIServices.updateAssetsTemplates({
                basic_assets: modifyData,
                brand_name,
            })

            return {
                Name: brand_name,
                Font: modifyData.fonts,
                Guide: modifyData.guidelines,
                Logo: modifyData.logo,
                no_guidelines: modifyData.no_guidelines,
                no_fonts: modifyData.no_fonts,
            }
        } catch (error) {
            const { setIsOpenErrorDialog } = globalErrorDialogStore.getState()
            setIsOpenErrorDialog(true)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (data !== currentEdit) {
            setCurrentEdit(data)
        }
    }, [data])

    const renderCard = (): JSX.Element => {
        if (!isHaveData) {
            return (
                <div className=" rounded-lg py-6 border-dashed border-2 outline-beige-outline m-6 flex justify-center">
                    <div>
                        <NeedleButton
                            onClickFunction={() => {
                                setIsEdit(true)
                                if (onFirstItemEditClick) {
                                    onFirstItemEditClick()
                                }
                            }}
                            buttonCharacter={getMarkName(type)}
                            buttonType={ButtonType.White}
                            border="px-3 py-10px"
                        />
                    </div>
                </div>
            )
        }
        return <DataDisplayCard data={data} type={type} currency={currency} />
    }

    const runEditState = (): JSX.Element => {
        switch (type) {
            case BrandsCardType.MultipleText:
                return (
                    <CardTypeMultipleText
                        placeHolder={placeHolder}
                        setCurrentListText={setCurrentEdit}
                        currentListText={currentEdit}
                        isDisplayNeedleHelp={isDisplayNeedleHelp && !isLink}
                        setIsDisable={setIsDisable}
                    />
                )
            case BrandsCardType.EIBasicAssets:
                return (
                    <CardTypeBrandAssets
                        setCurrentData={setCurrentEdit}
                        currentData={currentEdit}
                        setIsDisable={setIsDisable}
                    />
                )
            case BrandsCardType.Metrics:
                return (
                    <CardTypeMetrics
                        setCurrentData={setCurrentEdit}
                        currentData={currentEdit}
                        setIsDisable={setIsDisable}
                    />
                )
            case BrandsCardType.Customers:
                return (
                    <CardTypeCustomers
                        setCurrentCustomerData={setCurrentEdit}
                        currentCustomerData={currentEdit}
                        setIsDisable={setIsDisable}
                        isDisplayNeedleHelp={isDisplayNeedleHelp}
                        placeHolder={placeHolder}
                    />
                )
            case BrandsCardType.MultipleSelectDropdown:
                return (
                    <CardTypeSelectDropDown
                        setCurrentData={setCurrentEdit}
                        currentData={currentEdit}
                        setIsDisable={setIsDisable}
                    />
                )
            case BrandsCardType.ProductSelect:
                return (
                    <CardTypeSelectDropDown
                        setCurrentData={setCurrentEdit}
                        currentData={currentEdit}
                        setIsDisable={setIsDisable}
                        isProductType={true}
                    />
                )
            case BrandsCardType.Link:
                return (
                    <CardTypeLink
                        description={description}
                        setCurrentData={setCurrentEdit}
                        currentData={currentEdit}
                        setIsDisable={setIsDisable}
                    />
                )
            case BrandsCardType.LinkAndMedia:
                return (
                    <CardTypeLinkAndMedia
                        description={description}
                        setCurrentData={setCurrentEdit}
                        currentData={currentEdit}
                        setIsDisable={setIsDisable}
                    />
                )
            case BrandsCardType.Text:
            default:
                return (
                    <CardTypeText
                        placeHolder={placeHolder}
                        setCurrentText={setCurrentEdit}
                        currentText={currentEdit}
                        isDisplayNeedleHelp={isDisplayNeedleHelp && !isLink}
                        setIsDisable={setIsDisable}
                    />
                )
        }
    }

    return (
        <div>
            <div className="px-6 pt-6">
                <div className="flex justify-between">
                    <div className="flex flex-col flex-1">
                        <div className="flex flex-col">
                            <div className="mr-3 relative font-header-3">
                                {card}
                                {isHaveData && (
                                    <div className="inline-block relative">
                                        <div className="w-10 h-10 absolute -right-10 -bottom-[14px]">
                                            <Lottie
                                                options={doneIconAnimation}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {description && (
                            <div
                                className="font-small-text text-dark-blue pt-2"
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                            />
                        )}
                    </div>
                    {!isEdit && isHaveData && (
                        <div
                            onClick={() => {
                                setIsEdit(true)
                                if (onFirstItemEditClick) {
                                    onFirstItemEditClick()
                                }
                            }}
                            className="flex text-sm box-shadow rounded border-1px cursor-pointer border-dark-blue justify-center 
                            items-center flex-row h-28px ml-8 px-2"
                        >
                            <img
                                src="/assets/icons/edit-icon.svg"
                                alt="edit-icon"
                                className="h-4 w-4"
                            />
                            <div className="pl-4px font-dm font-semibold">
                                Edit
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {isEdit ? (
                <div>
                    {runEditState()}
                    <div className="bg-beige-outline h-1px" />
                    <div className="flex p-4 px-6 flex-row justify-end">
                        <div className="flex">
                            <NeedleButton
                                onClickFunction={() => {
                                    setIsEdit(false)
                                    setCurrentEdit(data)
                                }}
                                buttonType={ButtonType.White}
                                buttonCharacter="Cancel"
                            />
                        </div>
                        <div className="ml-2 flex">
                            <NeedleButton
                                onClickFunction={async () => {
                                    let dataReturn = undefined
                                    if (type === BrandsCardType.EIBasicAssets) {
                                        const dataSave =
                                            await handleUpdateData(currentEdit)
                                        if (dataSave) {
                                            dataReturn = await setValue(
                                                stateKey,
                                                dataSave
                                            )
                                        }
                                    } else {
                                        dataReturn = await setValue(
                                            stateKey,
                                            currentEdit
                                        )
                                    }
                                    if (dataReturn) {
                                        setIsEdit(false)
                                    }
                                }}
                                buttonCharacter={
                                    loading ? 'Saving...' : 'Save Changes'
                                }
                                isDisable={isDisable || loading}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                renderCard()
            )}
        </div>
    )
}

export default GetDataCard
